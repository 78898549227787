<script>
  import { setContext } from "svelte";
  import { LocalStore } from '../localStore.svelte';
  import { getLocalStorageKey } from '../utils';
  import Checkbox from "./Checkbox.svelte";
  import Textfield from "./Textfield.svelte";

  let { svecklistid = 1 } = $props();
  const localStorageKey = getLocalStorageKey(svecklistid);
  console.log(`ChecklistDisplay: localstoragekey: ${localStorageKey}`)

  let checklist = new LocalStore(localStorageKey, {schema: [], data: new Map()});
  // if(!checklist.value.schema){
  //   checklist.value.schema = [];
  // }
  // if(!checklist.value.data){
  //   checklist.value.data = new Map();
  // }
  const componentTypes = {
    checkbox: Checkbox,
    textfield: Textfield,
  }
  let options = [
    { compType: "checkbox" },
    { compType: "textfield"},
  ];

  let ChecklistItem = {};

  // let listSchema = $state([
  //   // {compType: "checkbox", text: "test", weight: 10, checked: false}
  // ]);
  // let data = $state([
  //   // {text: "", checked: false}
  // ]);
  // let checklistStorage = $state({
  //   schema: [],
  //   data: [],
  // });

  let selected = $state(options[0].compType);

  function fillItem(event) {
    const targetEl = event.target;
    const index = parseInt(targetEl.dataset.index);
    const name = targetEl.name;
    console.log(`fillItem name: ${name}`)
    switch (name) {
      case 'text':
        checklist.value.data.set(index, {textdata: targetEl.value});
        break;
      case 'checkbox':
        console.log(`checked... ${index}`)
        checklist.value.data.set(index, {checked: targetEl.checked});
        break;
      case 'textarea_data':
        checklist.value.data.set(index, {textdata: targetEl.value});
        break;
    }
    checklist.save();
  }

  setContext("fillItem", fillItem);

  // load data from localStorage
  function loadData (){
    checklist.value
    // const checklistStorageJSON = localStorage.getItem(localStorageKey);
    // if (checklistStorageJSON !== null) {
    //   return JSON.parse(checklistStorageJSON);
    // }
  }

  // $effect(() => {
  //   listSchema = checklistStorage.schema;
  // })

  // write data to localStorage
  // $effect(() => {
  //   checklistStorage.data = $state.snapshot(data);
  // })
  // $effect(() => {
  //   console.log("write data")
  //   $inspect(checklist.value)
  //   localStorage.setItem(
  //     localStorageKey,
  //     JSON.stringify($state.snapshot(checklist.value))
  //   );
  // })

  function getCheckedDataForIndex(i){
    console.log(`getCheckedDataForIndex: ${i}`)
    console.log(checklist.value.data)
    const value = checklist.value.data.get(i);
    if(value && value.hasOwnProperty('checked')){
      return value.checked
    }else{
      return false
    }
  }

  function getTextDataForIndex(i){
    console.log(`getTextDataForIndex: ${i}`)
    console.log(checklist.value.data)
    const value = checklist.value.data.get(i);
    if(value && value.hasOwnProperty('textdata')){
      console.log(`found text ${value.textdata}`)
      return value.textdata
    }
    else{
      return ''
    }
  }


</script>

{#each checklist.value.schema as box, i}
  <div class="sl-row">
    <svelte:component this={componentTypes[box.compType]} index={i}
      checked={getCheckedDataForIndex(i)}
      label={box.text}
      text={getTextDataForIndex(i)}
      weight={box.weight} mode="display" />
  </div>
{/each}

{#if !checklist.value.schema}
  No list items added.
{/if}

<style lang="scss">

  fieldset.flex {
    display: flex !important;
    gap: 1em;
    border: 1px solid var(--bs-secondary-bg, #abc2fb) !important;
    background-color: var(--bs-secondary-bg, #abc2fb);
    padding: 1em;
  }

  .sl-row {
    padding: 1em 1em;
    background-color: var(--pico-color-zinc-150, #ddd);

    --transition-property: transform;
    --transition-duration: 180ms;

    transition: var(--transition-property) var(--transition-duration) ease-in-out;
  }

  .sl-row:hover,
  .sl-row:focus-within {
    transform: scale(1.03);
  }

  [name="add"] {
    max-width: 12ch;
  }

  .sl-row:nth-child(odd){
    background-color: var(--pico-color-amber-50, #bbb);
  }
</style>
