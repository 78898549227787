<script>
  import { getContext } from "svelte";

  let {
    index,
    label,
    text,
    checked = $bindable(false),
    weight = $bindable(0),
    mode = "edit",
  } = $props();

  // let fillChecked = $state(false);
  const fillItem = getContext("fillItem");
  const editItem = getContext("editItem");
  const removeItem = getContext("removeItem");
</script>

<div class="box pico">
  <label class="checkbox">
    {#if mode === "edit"}
      &nbsp;
    {/if}
    <div>
    {#if mode === "edit"}
      <input
        type="checkbox"
        name="checkbox"
        data-index={index}
        onchange={editItem}
        bind:checked
      />
    {/if}
    {#if mode === "display"}
      <input
        type="checkbox"
        name="checkbox"
        data-index={index}
        onchange={fillItem}
        bind:checked
      />

    {/if}
    </div>
  </label>
  <label class="text">
    {#if mode === "edit"}
      check description
    {/if}
    {#if mode === "edit"}
      <textarea name="text" data-index={index} onchange={editItem}
        >{label}</textarea
      >
    {/if}
    {#if mode === "display"}
      <div class="text">{label}</div>
    {/if}
  </label>
  {#if mode === "edit"}
    <label class="weight">
      weight
      <div>
        <input
          type="text"
          name="weight"
          data-index={index}
          onchange={editItem}
          value={weight}
        />
      </div>
    </label>
  {/if}
  {#if mode === "edit"}
    <label class="remove">
      <div>
        <button onclick={removeItem} data-index={index}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-trash"
            viewBox="0 0 16 16"
          >
            <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"
            />
            <path
              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"
            />
          </svg>
        </button>
      </div>
    </label>
  {/if}
</div>

<style lang="scss">
  // @use "@picocss/pico/scss/pico";
  // @use "@picocss/pico/scss/colors" as *;

  .box {
    display: flex;
    width: 100% !important;
    align-items: center;
    gap: 1em;
    label {
      display: flex;
      flex-direction: column;
      > input,
      > textarea,
      > div {
        margin-top: 0;
        margin-bottom: 0;
        height: 5em;
      }
    }
    .text {
      flex-grow: 1;
    }
    .weight {
      max-width: fit-content;
      flex-basis: 7ch;
      flex-shrink: 1;
    }
  }
</style>
