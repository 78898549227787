// import MapifyTs from 'mapify-ts';
import { deserialize, serialize } from './utils';


export class LocalStore {
  value = $state();
  key = '';

  constructor(key, value) {
    this.key = key;
    this.value = value;

    // load data
    const item = localStorage.getItem(key);

    if (item) this.value = this.deserialize(item);
    console.log(`LocalStore: deserialized data: ${this.deserialize(item)}`)
    console.log(this.deserialize(item))

    $effect(() => {
      console.log("effect run inside store ;)")
      // this.save();
      localStorage.setItem(this.key, this.serialize($state.snapshot(this.value)));
    });
  }

  save() {
      localStorage.setItem(this.key, this.serialize($state.snapshot(this.value)));
      console.log(`LocalStore: serialized and saved: ${this.serialize($state.snapshot(this.value))}`)
  }

  serialize(value) {
    return serialize(value);
  }

  deserialize(item) {
    return deserialize(item);
  }
}

export function localStore(key, value) {
  return new LocalStore(key, value);
}
