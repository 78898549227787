<script>
  import ChecklistDisplay from './ChecklistDisplay.svelte';
  import ChecklistEdit from './ChecklistEdit.svelte';

  let { svecklistid = 1, mode = 'display' } = $props();
</script>

{#if mode === 'edit'}
  <ChecklistEdit svecklistid={svecklistid} />
{/if}

{#if mode === 'display'}
  <ChecklistDisplay svecklistid={svecklistid} />
{/if}

<style lang="scss">
</style>
