<script>
  import { setContext } from "svelte";
  import { LocalStore } from '../localStore.svelte';
  import { getLocalStorageKey } from '../utils';
  import Checkbox from "./Checkbox.svelte";
  import Textfield from "./Textfield.svelte";


  let { svecklistid = 1 } = $props();
  const localStorageKey = getLocalStorageKey(svecklistid);

  console.log(`ChecklistEdit: localstoragekey: ${localStorageKey}`)
  const componentTypes = {
    checkbox: Checkbox,
    textfield: Textfield,
  }
  let options = [
    { compType: "checkbox" },
    { compType: "textfield"},
  ];
  let checklist = new LocalStore(localStorageKey, {schema: [], data: new Map()});
  let ChecklistItem = {};

  let boxes = $state([
    // {compType: "checkbox", text: "test", weight: 10, checked: false}
  ]);

  let selected = $state(options[0].compType);

  function editItem(event) {
    const targetEl = event.target;
    const index = parseInt(targetEl.dataset.index);
    const name = targetEl.name;

    switch (name) {
      case 'text':
        boxes[index].text = targetEl.value;
        break;
      case 'checkbox':
        boxes[index].checked = targetEl.checked;
        break;
      case 'weight':
        boxes[index].weight = targetEl.value;
        break;
      case 'textarea':
        boxes[index].text = targetEl.value;
        break;
    }
  }

  function removeItem(event) {
    event.preventDefault()
    const targetEl = event.target;
    const index = parseInt(targetEl.dataset.index);
    if(boxes.length <= 1) {
      boxes = [];
    }
    boxes = boxes.filter((_, itemIdx) => {
      return itemIdx !== index;
    });
    console.log($state.snapshot(boxes))
  }

  setContext("editItem", editItem);
  setContext("removeItem", removeItem);

  // load data from localStorage
  function loadDataFromLocalStorage() {
    boxes = checklist.value.schema || [];
    // const checklistStorageJSON = localStorage.getItem(localStorageKey);
    // if (checklistStorageJSON !== null) {
    //   console.log(`load data from LS: ${checklistStorageJSON}`);
    //   const checklistStorage = JSON.parse(checklistStorageJSON);
    //   boxes = checklistStorage.schema;
    // }
  }

  // write data to localStorage
  $effect(() => {
    console.log($state.snapshot(boxes), typeof $state.snapshot(boxes));
    // if($state.snapshot(boxes).length === 0){
    //   return
    // }
    console.log(`store data in LS:`)
    console.log($state.snapshot(boxes))
    checklist.value.schema = $state.snapshot(boxes);
    checklist.value.data = new Map();
    // checklist.save()
    // let checklistStorage = $state({
    //   schema: boxes || [],
    //   data: {},
    // })
    // localStorage.setItem(
    //   localStorageKey,
    //   JSON.stringify($state.snapshot(checklistStorage))
    // );
  })


  function addItem(event) {
    // boxes = [...boxes, new ChecklistItem({compType: $state.snapshot(selected)})]
    console.log($state.snapshot(boxes))
    boxes = [...boxes, {compType: $state.snapshot(selected), checked: false, text: '', weight: 0}]
  }

  loadDataFromLocalStorage();
</script>

<fieldset class="flex pico">
  <select bind:value={selected}>
    {#each options as option}
      <option value={option.compType}>{option.compType}</option>
    {/each}
  </select>
  <input type="button" name="add" value="add box" onclick={addItem} />
</fieldset>

{#each boxes as box, i}
  <div class="sl-row">
    <svelte:component this={componentTypes[box.compType]} index={i} label={box.text} checked={box.checked} weight={box.weight} />
  </div>
{/each}

{#if !boxes.length}
  No list items added.
{/if}

<style lang="scss">

  // @use "$pico/pico.conditional.scss";
  // @use "$pico/colors/_index.scss" as *;
  // @use "$pico/colors/_index.scss" as *;


  fieldset.flex {
    display: flex !important;
    gap: 1em;
    border: 1px solid var(--bs-secondary-bg, #abc2fb) !important;
    background-color: var(--bs-secondary-bg, #abc2fb);
    padding: 1em;
  }

  .sl-row {
    padding: 1em 1em;
    background-color: var(--pico-color-zinc-150, #ddd);

    --transition-property: transform;
    --transition-duration: 180ms;

    transition: var(--transition-property) var(--transition-duration) ease-in-out;
  }

  .sl-row:hover,
  .sl-row:focus-within {
    transform: scale(1.03);
  }

  [name="add"] {
    max-width: 12ch;
  }

  .sl-row:nth-child(odd){
    background-color: var(--pico-color-amber-50, #bbb);
  }
</style>
